import React, { useState } from "react"
import VisibilitySensor from "react-visibility-sensor"
const AnimationWrap = ({
  children,
  minTopValue = 100,
  className = "fadeInUp",
}) => {
  const [visible, setV] = useState(false)
  return (
    <VisibilitySensor
      minTopValue={minTopValue}
      partialVisibility={true}
      onChange={isVisible => {
        if (!visible && isVisible) {
          setV(true)
        }
      }}
    >
      <div className={visible ? className : "transparent"}>{children}</div>
    </VisibilitySensor>
  )
}

export default AnimationWrap
